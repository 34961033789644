import React from "react";
import CtaBanner from "../components/cta-banner";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";
import ServicesHero from "../components/services/hero";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: file(relativePath: { eq: "meet-sarah.jpg" }) {
				publicURL
				childImageSharp {
					gatsbyImageData(
						quality: 100
						placeholder: BLURRED
						formats: [AUTO, WEBP]
					)
				}
			}
		}
	`);

	return (
		<Layout>
			<GatsbySeo
				title="About Me | Sarah Bennett Commercial Photography"
				description="Im Sarah Bennett, a Commercial Photographer based in Sussex and covering southeast England. I specialise in business, branding, food and product photography."
				language="en"
				canonical="https://www.sarah-bennett.com/about-us"
				openGraph={{
					url: "https://www.sarah-bennett.com/about-us",
					title: "About Me | Sarah Bennett Commercial Photography",
					description:
						"Im Sarah Bennett, a Commercial Photographer based in Sussex and covering southeast England. I specialise in business, branding, food and product photography.",
					images: [
						{
							url: `${data.heroImg.publicUrl}`,
							width: 800,
							height: 600,
							alt: "Experienced Commercial Photographer in East and West Sussex",
						},
					],
				}}
			/>
			<ServicesHero
				headline={["Meet", <br />, "Sarah"]}
				img={data.heroImg.childImageSharp.gatsbyImageData}
				showBtn={false}
			/>
			<section>
				<Container>
					<Row>
						<Col>
							<p>
								Hi, I’m Sarah and I have been passionate about photography ever
								since I was a young girl staring with fascination at my
								grandfather’s photographs of the polo ponies, taken in India
								just before the outbreak of World War II. My Uncle Peter, who
								was a wedding photographer, was also a huge inspiration for me.
								I haven’t always been a photographer, but when I look back over
								my life I realise that I have gone full circle, from visiting my
								uncle’s dark room and watching with awe as he developed his
								photographs and waiting with bated breathe for the magic to
								happen, to working as a commercial photographer and imagining
								images in my mind before they are taken.
							</p>
							<p>
								I starting my working life in insurance and banking after
								leaving school and deciding I had had enough of education. I
								loved art at school and was awarded an ‘A’ grade in my Art GCSE,
								so to stay true to myself, I started to look for more creative
								jobs and ended up working for Spire FM in radio for years, in my
								home town of Salisbury. I went on to work in London selling
								advertising space for the Independent newspaper and then Smooth
								Radio. In this work I really enjoyed talking to people and
								finding out about them and their business and then writing
								adverts that reflected the image they wanted to portray. Just
								like now, I loved the process of creating something out of
								nothing.
							</p>
							<p>
								Always an avid photographer, it was when my son Arthur was born
								that I decided that I no longer wanted to work in London and
								that what I really wanted to do was be a professional
								photographer. I experimented with lots of different types of
								photography before finally deciding that commercial photography
								was what I enjoyed the most and I realised that my sales
								background perfectly complemented the skills required to be a{" "}
								<Link to="/">commercial photographer</Link>. So, in May 2018, I
								set up Sarah Bennett Commercial Photography, and I can honestly
								say that I love what I do.
							</p>
							<p>
								As a commercial photographer, I especially enjoy the creative
								side of{" "}
								<Link to="/services/business-branding">
									business branding photography
								</Link>{" "}
								and{" "}
								<Link to="/services/personal-branding">
									personal branding photography
								</Link>
								. One of my favourite
								<Link to="/services/business-branding">
									{" "}
									business branding{" "}
								</Link>
								shoots was for South Over Food factory. During this
								manufacturing photography shoot, I photographed the entire
								manufacturing process, from the raw product to packaged and
								ready for delivery. I found the whole experience so interesting
								and the huge number of photographs that the client required
								afforded me the opportunity to get plenty of great shots for the
								client to use for their branding and marketing campaigns.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Want to find out how I can help you?"
				btnText="Get in Touch"
				url="/contact-us"
			/>
			<section>
				<Container>
					<Row className="align-items-center pt-3">
						<Col xs={12} md={6}>
							<StaticImage
								src="../images/millie.jpg"
								alt="Millie"
								style={{ height: "250px" }}
							/>
						</Col>
						<Col>
							<p>
								When I’m not working, I love walking with my Cockapoo in the
								Sussex countryside and spending time with my son and husband.
								I’m also a keen gardener and love nothing more than pottering
								around in the garden. I’m also a big fan of clothes shopping.
							</p>
						</Col>
					</Row>
					<Row>
						<Col>
							<p>
								What I love about taking pictures is that I create a visual
								representation of my client’s business, brand or products from
								nothing but my imagination. I am a firm believer in trust and I
								recognise that my clients are trusting me with their reputation
								and image and I take my responsibility to deliver excellent
								commercial photographs very seriously. I am always open and
								honest with people and try to help them, even if they are
								looking for a service I don’t offer.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="If you would like to find out how I can help you best visually represent your brand or business please get in touch."
				btnText="Let's Talk"
				url="/contact-us"
			/>
		</Layout>
	);
};

export default AboutUsPage;
